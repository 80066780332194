<template>
  <div >
    <v-container class="my-5">

        <v-layout row wrap justify-center>
          <v-flex xs10 md10 xl8>
            <template>
              <v-carousel
                cycle
                :show-arrows="false"
              >
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                 ></v-carousel-item>
              </v-carousel>
            </template>
          </v-flex>
        </v-layout>      
      <div class="my-5">
        <h1 class ="subheading grey--text ml-10">Dobro došli!</h1>
      </div>  
        <v-layout row wrap justify-center>
          <v-flex xs10 md8 xl6 >
            <div class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4 
              text-justify"
            >
              <p>Preduzeće DOMUZ-TRANS doo registrovano je 1992. godine kao transportno i trgovinsko društvo sa ograničenom odgovornošću. Sjedište preduzeća je Kremna b.b. Prnjavor </p>
              <p>Domuz-trans d.o.o. se bavi redovnim linijskim i vanlinijskim prevozom putnika u domaćem i međunarodnom saobraćaju. Firma zapošljava 14 radnika. Posjeduje 10 vozila, kao i sopstveni servis za odrzavanje vozila.</p>
              <p>Vršimo prevoz putnika u prigradskom saobaćaju na liniji Kulaši - Prnjavor prema rasporedu na stranici Prigradski saobraćaj.</p>
              <p>Održavamo međunarodnu linije Teslić-Koper i Prnjavor - Koper prema rasporedu na stranici Međunarodni saobraćaj.</p>
              <p>Za potrebe naših klijenata organizujemo vanlinijski prevoz u zemlji i inostranstvu.</p>
              
            </div>
          </v-flex>
        </v-layout>  
    </v-container>    
  </div>
</template>
<script>

export default {

  data () {
      return {
        items: [
          {
            src: "https://www.domuztrans.com/img/domuztrans13.jpg",
          },
          {
            src: 'https://www.domuztrans.com/img/domuztrans2.jpg',
          },
          {
            src: "https://www.domuztrans.com/img/domuztrans9.jpg",
          },
          
        ],
      }
    },


};
</script>